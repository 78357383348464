import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GatsbyBrowser } from 'gatsby'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  pathname,
  prevRouterProps,
}) => {
  const pathArray = pathname.match(/[^/]+/g) // 遷移先パスが格納された配列(スラッシュで区切られた文字列を配列として取得)
  const prevPathArray = prevRouterProps?.location.pathname.match(/[^/]+/g) // 遷移元パスが格納された配列(スラッシュで区切られた文字列を配列として取得)

  const isFromCasePages = prevPathArray?.[0] === 'cases'
  const isFromCaseTopPage = isFromCasePages && prevPathArray.length === 1
  const isFromCaseFilterPages =
    isFromCasePages &&
    ['objective', 'industry', 'gift'].includes(prevPathArray[1])
  const isFromFixScroll = isFromCaseTopPage || isFromCaseFilterPages

  const isToCasePages = pathArray?.[0] === 'cases'
  const isToCaseTopPage = isToCasePages && pathArray.length === 1
  const isToCaseFilterPages =
    isToCasePages && ['objective', 'industry', 'gift'].includes(pathArray[1])
  const isToFixScroll = isToCaseTopPage || isToCaseFilterPages

  if (isFromFixScroll && isToFixScroll) {
    // 遷移元および遷移先が導入事例一覧系ページの場合、スクロールさせない("cases", "cases/objective/*", "cases/industry/*",  "cases/gift/*")
    // NOTE: 導入事例一覧系ページのタグクリック時はスクロールさせたくないため、当処理を追加。なお、別ページから導入事例一覧系ページへ遷移した場合は条件に合致せず、通常通りスクロール位置は一番上になる。
    return false
  }

  return [0, 0]
}
